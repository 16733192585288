import React from "react";
import styled from "styled-components";
import Image from "../Image";
import { Button } from "../Button";
import { navigate } from "gatsby";

const Box = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ImageContainer = styled.div`
  display: grid;
  justify-items: center;

  ${({ largeImage }) => largeImage && 'grid-template-rows: 220px;'}

  margin-bottom: 20px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  height: 100%;

  width: 293px;
  padding-bottom: 20px;
  border-radius: 12px;
  border: 1px solid #8080803b;
`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 22px;
  font-weight: 400;
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};
  max-width: 330px;
  text-align: center;
  padding-top: 40px;
  padding-right:30px;
  padding-left:30px;
  margin-bottom: 0;
  line-height: 28px;
`;

const Description = styled.p`
  max-width: 280px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 15px;
  line-height: 19px;
  color: gray;
  padding-right:15px;
  padding-left:15px;
  margin-bottom: 11px;
  font-weight:400;
  padding-top: 10px;
`;

const Paragrafy = styled.p`
  max-width: 290px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 500;
  padding-right:20px;
  padding-left:20px;
  margin-bottom: 20px;

  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const FeatureBox = ({
  title,
  description,
  imagePath,
  href,
  blueStroke = false,
  pushImageToBottom,
  backgroundColor,
  fullWidth,
  badgeNumber,
  titleColor,
  maxWidth,
  smallTitle,
  paragrafy,
  SVGImage,
  largeImage,
  onLinkClick
}) => {
  return (
    <Box>
      <ImageContainer largeImage={largeImage} backgroundColor={backgroundColor} blueStroke={blueStroke}>
        {imagePath && <Image
          pushImageToBottom={pushImageToBottom}
          width={fullWidth ? "70%" : maxWidth ? maxWidth : "40%"}
          filename={imagePath}
          alt="Feature image"
        />}

        {SVGImage && SVGImage()}

        <Title fontSize={smallTitle ? "20px" : "24px"} color={titleColor}>
          {title}
        </Title>
        <Description>
          {description} <Paragrafy onClick={onLinkClick}>{paragrafy}</Paragrafy>
        </Description>
      </ImageContainer>

      {href ? (
        <Button primary small onClick={() => navigate("/create-account")}>
          Criar conta
        </Button>
      ) : null}
    </Box>
  );
};

export default FeatureBox;