import React from "react";

import { Header } from "../components/Header";
import { Layout } from "../components/Layout";
import Home from "../sections/Home/Home";
import Services from "../sections/Home/Services";
import { SEO } from "../components/SEO";
import Who from "../sections/Home/Who";
import ContactSection from "../sections/Home/ContactSection";
import ProjectsSection from "../sections/Home/Projects";
import GoogleMap from "../sections/Home/Map";
import FooterSection from "../sections/Home/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Header />
      <Home />
      <Services />

      <ProjectsSection />

      <Who />
      <ContactSection />
      <GoogleMap />
      <FooterSection />
      

    </Layout>
  );
};

export default IndexPage;
