import React, { useState } from "react";
import styled from "styled-components";
import { InputContainer, InputStyle, Label } from "./SharedStyles";

export const Input = styled.input`
  ${InputStyle};
  box-shadow: 0px 3px 6px #00000000;
`;

export const TextInputLarge = ({
  id,
  name = "",
  type = "",
  title = "",
  value = "",
  error,
  invertColors = false,
  required = false,
  onChange,
  onBlur,
}) => {
  const [inputFocused, toggleInputFocus] = useState(false);

  const handleFocus = () => {
    toggleInputFocus(!inputFocused);
  };

  const handleBlur = (event) => {
    toggleInputFocus(!inputFocused);
    onBlur(event);
  };

  const isFilledOrActive = () => {
    return inputFocused || value;
  };

  return (
    <InputContainer large>
      <Input
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        focused={isFilledOrActive()}
        value={value}
        required={required}
        error={error}
        invertColors={invertColors}
        area-invalid={error}
      />
      <Label
        htmlFor={name}
        focused={isFilledOrActive()}
        error={error}
        invertColors={invertColors}
      >
        {title}
      </Label>
    </InputContainer>
  );
};
