import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../../components/Container";
import { Button } from "../../components/Button";
import PuffLoader from "react-spinners/PuffLoader";
import { useSnackbar } from "react-simple-snackbar";
import { useTheme } from "styled-components";
import { successSnackbar, errorSnackbar } from "../../shared/SnackbarSettings";
import { TextAreaInput } from "../../components/Inputs";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { isEmail } from "../../validators";
import { CONTACT_URL } from "../../API/ApiConstants";
import { TextInputLarge } from "../../components/Inputs/TextInputLarge";
import ContactInImageSVG from "../../assets/images/contact/in.inline.svg";
import ContactTellImageSVG from "../../assets/images/contact/tell.inline.svg";
import ContactEmailImageSVG from "../../assets/images/contact/email.inline.svg";
import { down } from "styled-breakpoints";
import { Fade } from "react-reveal";

const H1 = styled.h1`
  font-size: 25px;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const P = styled.p`
  font-size: 16px;
  margin-top: 30px;
  margin-left: 13px;
`;

const Div = styled.div`
  display: flex;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const ContactSectionContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.grid.maxWidth};
  padding: 0 ${({ theme }) => theme.grid.horizontalPadding};
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom:40px;
  padding: 10px;

  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;

const ContactContainer = styled(Container)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 20px;
  position: relative;
  padding: 0 40px;
`;

const ContainerContact = styled.div`
  flex: 1;
`;

const ContactOneImage = styled(ContactEmailImageSVG)`
  ${down("md")} {
    width: 40px;
  } 
`;

const ContactTwoImage = styled(ContactTellImageSVG)`
  ${down("md")} {
    width: 40px;
  } 
`;

const ContactThreeImage = styled(ContactInImageSVG)`
  ${down("md")} {
    width: 40px;
  } 
`;

const ContactForm = styled.form``;

const ContactSection = () => {
  const { colors } = useTheme();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleSubmit, errors, control, reset } = useForm();

  const [openSuccessSnackbar] = useSnackbar(successSnackbar(colors));
  const [openErrorSnackbar] = useSnackbar(errorSnackbar(colors));

  const onSubmit = async (formValues) => {
    try {
      setIsSubmitting(true);
      formValues.phone = formValues.phone.slice(3)?.replace(/\D/g, "");

      const response = await fetch(CONTACT_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

      const { data: message } = await response.json();

      openSuccessSnackbar(message);

      reset(
        {
          name: "",
          email: "",
          phone: "",
          message: "",
        },
        {
          errors: false,
          dirtyFields: false,
          isDirty: false,
          isSubmitted: false,
          touched: false,
          isValid: false,
          submitCount: false,
        }
      );
    } catch (error) {
      openErrorSnackbar("Falha ao enviar o contato");
    }
    setIsSubmitting(false);
  };

  function phoneMask(val) {
    const prefix = `+55 (${val.substring(2, 4)})`;

    if (val.length <= 12) {
      return prefix + " " + val.substring(4, 8) + "-" + val.substring(8, 12);
    }

    return prefix + " 9 " + val.substring(5, 9) + "-" + val.substring(9, 13);
  }

  return (
    <Fade bottom cascade>
      <ContactSectionContainer id="Contato">
        <ContainerContact name="Contato">
          <H1>Entre em contato</H1>
          <Div>
            <ContactOneImage />
            <P>contato@scarattisolucoesemti.com.br </P>
          </Div>
          <Div>
            <ContactTwoImage />
            <P>41 99979-6564</P>
          </Div>
          <Div>
            <ContactThreeImage />
            <P>linkedin.com/company/scarattisolucoesemti</P>
          </Div>
        </ContainerContact>

        <ContactContainer>
          <ContactForm onSubmit={handleSubmit(onSubmit)}>
            <Controller
              id="name"
              type="text"
              title="Nome"
              name="name"
              error={errors.name}
              as={TextInputLarge}
              control={control}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 100,
              }}
              required
            />
            <Controller
              id="email"
              type="email"
              title="Email"
              name="email"
              error={errors.email}
              as={TextInputLarge}
              control={control}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 150,
                validate: (value) => isEmail(value),
              }}
              required
            />
            <Controller
              id="phone"
              type="text"
              title="Telefone"
              name="phone"
              error={errors.phone}
              as={
                <NumberFormat
                  customInput={TextInputLarge}
                  format={phoneMask}
                  mask="_"
                />
              }
              control={control}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 20,
              }}
              required
            />
            <Controller
              id="message"
              title="Mensagem"
              name="message"
              as={TextAreaInput}
              control={control}
              error={errors.message}
              rows={4}
              maxLength={500}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 500,
              }}
              required
            />

            <Button quiet>
              {isSubmitting === false ? (
                "Enviar"
              ) : (
                  <PuffLoader color={colors.white} size={20} />
                )}
            </Button>
          </ContactForm>
        </ContactContainer>
      </ContactSectionContainer>
    </Fade>
  );
};
export default ContactSection;
