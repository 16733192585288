import React from "react";
import Section from "../../components/Section";
import styled from "styled-components";
import { Container } from "../../components/Container";
import FeatureBox from "../../components/FeatureBox/FeatureBox";
import { up } from "styled-breakpoints";
import theme from "../../../theme";
import CellImageSVG from "../../assets/images/services/cell-image.inline.svg";
import BackImageSVG from "../../assets/images/services/back-image.inline.svg";
import HappyImageSVG from "../../assets/images/services/happy-image.inline.svg";
import StarImageSVG from "../../assets/images/services/star-image.inline.svg";
import { Button } from "../../components/Button";
import { Slide } from "react-reveal";


const PhoneImage = styled(CellImageSVG)`
  width: 100%;
  height: 100%;
  margin-top:50px;
  padding-bottom: 55px;
`;

const BackImage = styled(BackImageSVG)`
width: 100%;
height: 100%;
margin-top:50px;
padding-bottom: 55px;
`;

const HappyImage = styled(HappyImageSVG)`
width: 100%;
height: 100%;
margin-top:50px;
padding-bottom: 55px;
`;

const StarImage = styled(StarImageSVG)`
width: 100%;
height: 100%;
margin-top:50px;
padding-bottom: 55px;
`;

const FeaturesList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;

  ${up("lg")} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const DivButton = styled.div`
  margin-left: 480px;
`;

const Services = () => {
  const features = [
    {
      title: "Desenvolvimento  de Sites e Aplicativos",
      description:
        "Desenvolvemos de acordo com a necessidade do seu negócio e alinhados ao mercado que está em constante evolução.",
      svgImage: <PhoneImage />
    },
    {
      title: "Workflow de Processos",
      description:
        "Desenhamos o fluxo do seu projeto desde a ideação de funcionalidades até o lançamento para o público-alvo.",
      svgImage: <BackImage />
    },
    {
      title: "Conceito e desing da Experiência.",
      description:
        "Desenhamos sites e aplicativos com o foco em usabilidade e sempre alinhados à identidade da sua empresa.",
      svgImage: <HappyImage />
    },
    {
      title: "Atendimento de Qualidade.",
      description:
        "Garantimos todo suporte e atendimento ágil para acompanhar e implementar melhorias em seu software.",
      svgImage: <StarImage />
    },
  ];


  return (
    <Section.Container id="Serviços" variant="dark">
      <Container>
        <Section.Header name="Serviços" variant="dark"></Section.Header>
        <FeaturesList>
          <Slide top cascade>
            {features.map((feature, index) => (
              <FeatureBox
                key={index}
                title={`${feature.title}`}
                description={feature.description}
                href={feature.href ? feature.href : null}
                pushImageToBottom={feature.pushImageToBottom}
                backgroundColor={theme.colors.white}
                titleColor={theme.colors.black}

                SVGImage={() => { return feature.svgImage }}
              />
            ))}
          </Slide>
        </FeaturesList>
        {/* <DivButton>
          <Button primary onClick={() => {
            document.getElementById('Projetos').scrollIntoView({
              behavior: 'smooth'
            });
          }}>
            Veja nossos projetos
        </Button>
        </DivButton> */}
      </Container>
    </Section.Container>
  );
};

export default Services;
