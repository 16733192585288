import React from "react";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import Section from "../../components/Section";
import { Container } from "../../components/Container";
import Slider from "react-slick";
import Image from "../../components/Image";
import { Button } from "../../components/Button";
import WhoImageSVG from "../../assets/images/who/whoweare-image.inline.svg";
import { Fade } from "react-reveal";

const ContainerAll = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.grid.maxWidth};
  padding: 0 ${({ theme }) => theme.grid.horizontalPadding};
  margin: 0 auto;

  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;

const ContainerOne = styled.div`
  flex: 1;
  flex-direction: column;
  margin: 0;
  position: relative;
  margin-top: 46px;

  ${down("md")} {
    margin-top: 0px;
  }
`;

const ContainerTwo = styled.div`
  flex: 1;
  flex-direction: column;
  margin: 0 20px;
  position: relative;
  height: 100%;
`;

const Title = styled.h1`
  color: white;
  font-size: 28px;
  font-family: ${({ theme }) => theme.fonts.secondary};
`;

const P = styled.p`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight:300;
`;

const WhoImage = styled(WhoImageSVG)`
  width: 90%;
  height: 90%;
  position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Who = () => {
  return (
    <Section.Container id="Quem Somos" variant="dark">
      <ContainerAll>
        <Fade left>
          <ContainerOne>
            <Title>Quem Somos</Title>
            <P>
              {" "}
              A SCARATTI Soluções em TI atua no mercado de tecnologia há <br />
              mais de 11 anos e desde o início é especializada em <br />
              desenvolvimento de projetos Web e Mobile. Criamos e <br />
              aplicamos várias soluções inteligentes de negócios ao <br />
              longo da nossa história, sempre buscando sucesso e <br />{" "}
              atendimento de qualidade.
            </P>

            <Button primary onClick={() => {
              document.getElementById('Contato').scrollIntoView({
                behavior: 'smooth'
              });
            }}>
              Entre em contato
        </Button>
          </ContainerOne>
        </Fade>

        <ContainerTwo>
          <WhoImage />
        </ContainerTwo>
      </ContainerAll>
    </Section.Container>
  );
};

export default Who;
