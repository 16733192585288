import React, { useState } from "react";
import styled, { css } from "styled-components";
import {
  FormGroup,
  InputContainer,
  InputStyle,
  Label,
  FocusedLabelStyle,
  ErrorTextStyle,
} from "./SharedStyles";
import { ErrorMessage } from "./ErrorMessage";

const TextArea = styled.textarea`
  ${InputStyle};
`;

const TextAreaContainer = styled(InputContainer)`
  align-items: flex-start;
`;

const FocusedTextAreaLabelStyle = css`
  ${FocusedLabelStyle};
`;

const TextAreaLabel = styled(Label)`
  top: 25px;

  ${({ focused }) => focused && FocusedTextAreaLabelStyle};
  ${({ error }) => error && ErrorTextStyle};
`;

export const TextAreaInput = ({
  id,
  name = "",
  title = "",
  value = "",
  error,
  rows = 4,
  maxLength,
  required = false,
  onChange,
  onBlur,
}) => {
  const [inputFocused, toggleInputFocus] = useState(false);

  const handleFocus = () => {
    toggleInputFocus(!inputFocused);
  };

  const handleBlur = (event) => {
    toggleInputFocus(!inputFocused);
    onBlur(event);
  };

  const isFilledOrActive = () => {
    return inputFocused || value;
  };

  return (
    <FormGroup>
      <TextAreaContainer>
        <TextArea
          id={id}
          name={name}
          rows={rows}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          focused={isFilledOrActive()}
          value={value}
          required={required}
          error={error}
          maxLength={maxLength}
          area-invalid={error}
        />
        <TextAreaLabel
          htmlFor={name}
          focused={isFilledOrActive()}
          error={error}
        >
          {title}
        </TextAreaLabel>
      </TextAreaContainer>
      <ErrorMessage error={error} />
    </FormGroup>
  );
};
