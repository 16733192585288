import styled, { css } from "styled-components";
import { down } from "styled-breakpoints";

const PrimaryButton = css`
  background: ${({ theme }) => theme.colors.button.primary.background};
  color: ${({ theme }) => theme.colors.button.primary.text};
`;

const SecondaryButton = css`
  background: ${({ theme }) => theme.colors.button.secondary.background};
  color: ${({ theme }) => theme.colors.button.secondary.text};
`;

const QuietButton = css`
  background: ${({ theme }) => theme.colors.button.primary.background};
  color: ${({ theme }) => theme.colors.button.primary.text};
  margin-left: 408px;
`;



const SmallVariant = css`
  font-size: 16px;
  padding: 6px 15px;
`;

export const Button = styled.button`
  letter-spacing: 0px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 14px 30px;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;
  box-shadow: 0px 2px 5px #00000029;
  margin-top: 30px;

  ${down("md")} {
    margin: 0;
  
  }

  ${({ primary }) => primary && PrimaryButton};
  ${({ secondary }) => secondary && SecondaryButton};
  ${({ quiet }) => quiet && QuietButton};
  ${({ small }) => small && SmallVariant};
`;
