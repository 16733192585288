import React from "react";
import Img from "gatsby-image/withIEPolyfill";
import { useStaticQuery, graphql } from "gatsby";

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const image = data.images.edges.find((n) => {
    return n.node.relativePath.includes(props.filename);
  });

  if (!image) {
    return null;
  }

  return (
    <Img
      alt={props.alt}
      fluid={image.node.childImageSharp.fluid}
      objectFit="contain"
      style={{ ...props.style }}
      loading="lazy"
      fadeIn={false}
      style={{
        height: props.height ? props.height : "none",
        width: props.width ? props.width : "none",
        marginTop: props.pushImageToBottom ? "auto" : "unset",
      }}
    />
  );
};
export default Image;
