import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import { useTheme } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

export const SEO = ({ page }) => {
  const { colors } = useTheme();
  const { site } = useStaticQuery(SEOQuery);

  const {
    title,
    titleTemplate,
    description,
    keywords,
    siteUrl,
    image,
    openGraph,
    googleSiteVerificationID,
  } = site.siteMetadata;

  const SEOSettings = {
    url: new URL(page?.path || "", siteUrl),
    title: page?.title || title,
    description: page?.description || description,
    titleTemplate,
    keywords,
    image: `${siteUrl}${image}`,
    openGraph,
    googleSiteVerificationID,
  };

  return (
    <ReactHelmet
      title={SEOSettings.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{ lang: "pt-BR" }}
    >
      <meta charSet="utf-8" />
      <meta name="description" content={SEOSettings.description} />
      <meta name="keywords" content={SEOSettings.keywords} />
      <meta name="image" content={SEOSettings.image} />
      <meta
        name="google-site-verification"
        content={SEOSettings.googleSiteVerificationID}
      />
      <meta name="theme-color" content={colors.white} />
      <meta itemProp="name" content={SEOSettings.title} />
      <meta name="description" content={SEOSettings.description} />
      <meta name="og:title" content={SEOSettings.openGraph.title} />
      <meta property="og:url" content={SEOSettings.url} />
      <meta name="og:site_name" content={SEOSettings.title} />
      <meta name="og:description" content={SEOSettings.openGraph.description} />
      <meta property="og:url" content={SEOSettings.openGraph.url} />
      <meta property="og:image" content={SEOSettings.image} />
      <meta name="og:locale" content="pt_BR" />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={SEOSettings.title} />
      <meta name="twitter:description" content={SEOSettings.description} />
      <meta name="twitter:image" content={SEOSettings.image} />
    </ReactHelmet>
  );
};

const SEOQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
        keywords
        titleTemplate
        description
        image
        googleSiteVerificationID
        openGraph {
          title
          description
        }
      }
    }
  }
`;
