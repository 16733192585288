import React from 'react';
import GoogleMapReact from 'google-map-react';
import Section from '../../components/Section';
import styled from 'styled-components';
import MapImageSVG from "../../assets/images/map/busca-image.inline.svg";
import ArrowImageSVG from "../../assets/images/map/seta-image.inline.svg";

const defaultProps = {
  center: {
    lat: -25.42764686109093,
    lng: -49.26319909785495,
  },
  zoom: 15,
}

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 616px;
`;

const renderMarkers = (map, maps) => {
  let marker = new maps.Marker({
    position: { lat: defaultProps.center.lat, lng: defaultProps.center.lng },
    map,
    title: 'Scaratti Soluções em TI',
    optimized: false,
    label: {
      fontSize: "8pt",
      text: "Scaratti Soluções em TI"
    }
  });
  return marker;
};

const DivContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0px;
  margin: 0px;
  max-width: none;
  opacity: 1;
  transform: translate(-50%, -100%);
`;

const ContainerPin = styled.div`
 background-color: #212736;
 min-width: 280px;
 min-height: 80px;
 border-radius: 10px;
 max-height: 104px;
`;

const BuscaImage = styled(MapImageSVG)`
  width: 80px;
  height: 70px;
  margin-top: 24px;
  margin-left: 100px;
  color: #36D8B4;
`;

const SetaImage = styled(ArrowImageSVG)`
  width: 40px;
  height: 50px;
  color:#212736;
`;

const PPin = styled.p`
  color: white;
  padding: 25px;
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 200;
  line-height: 24px;
  text-align: center;
`;

const GoogleMap = () => (
  <Section.Container id="">
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDQMwkxnLWyqpWf3XepzA4jQ7bHQGKZRGU", v: '3.31' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        style={{ minHeight: '616px' }}
        yesIWantToUseGoogleMapApiInternals
      // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
        <DivContainer lat={defaultProps.center.lat} lng={defaultProps.center.lng}>
          <ContainerPin>
            <PPin>Rua XV de Novembro, n° 1155 <br /> Sala 1410, Centro, Curitiba-PR  <SetaImage /></PPin>
          </ContainerPin>
          <BuscaImage />
        </DivContainer>
      </GoogleMapReact>
    </MapContainer>
  </Section.Container>
)

export default GoogleMap