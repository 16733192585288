import React from "react";
import { down } from "styled-breakpoints";

import styled from "styled-components";
import { Container } from "../../components/Container";
import { Button } from "../../components/Button";
import HeroImageSVG from "../../assets/images/home/hero-image.inline.svg";
import { Fade } from "react-reveal";

const HomeSectionContainer = styled.div`
  min-height: 616px;
  max-width: 100%;
  background-color:${({ theme }) => theme.colors.lightGray}; 

  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;

const HeroContainer = styled(Container)`
  flex: 1;
  margin: 70px 50px 70px 0px;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  border-radius: 0px 20px 20px 0px;
  position: relative;
  height: 80%;
  min-height: 160px;

  ${down("md")} {
    border-radius: 20px;
  }
`;

const HomeContainer = styled.div`
  flex: 1;
  padding-left: 60px;

  ${down("md")} {
    padding: 10px;
  }
`;

const H1 = styled.p`
  color: black;
  font-size: 30px;
  margin-top: 200px;
  font-weight: 700;

  line-height: 35px; // TODO

  ${down("md")} {
    margin-top: 40px;
  }

  span {
    color: ${({ theme }) => theme.colors.green};
    font-weight: 700;
  }
`;


const P = styled.p`
  color: gray;
  font-size: 15px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.secondary};
`;

const HeroImage = styled(HeroImageSVG)`
  width: 120%;
  height: 120%;
  position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ActionButton = styled(Button)`
  ${down("md")} {
    width: 100%;
  }
`;

const HomeSection = () => {
  return (
    <HomeSectionContainer id="">
      <HeroContainer>
        <HeroImage />
      </HeroContainer>

      <Fade right>
        <HomeContainer>
          <H1>
            Tiramos seu{" "}
            <span>
              aplicativo</span>, <span>site <br /> ou sistema{" "}
            </span>
          do papel
        </H1>
          <P>
            Encontramos, desenvolvemos e aplicamos soluções <br /> sob medida para o seu
          negócio
        </P>
          <ActionButton primary onClick={() => {
            document.getElementById('Serviços').scrollIntoView({
              behavior: 'smooth'
            });
          }}>
            Conheça nossos serviços
        </ActionButton>
        </HomeContainer>
      </Fade>
    </HomeSectionContainer>
  );
};

export default HomeSection;
