import styled, { css } from "styled-components";

export const FocusedLabelStyle = css`
  color: transparent;
  padding: 0 10px;
`;

export const ErrorTextStyle = css`
  color: ${({ theme }) => theme.colors.error};
`;

export const LabelStyle = css`
  position: absolute;
  left: 15px;
  transition: 0.2s;
  cursor: text;
  opacity: 0.75;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray};

  ${({ error }) => error && ErrorInputStyle};
  ${({ focused }) => focused && FocusedLabelStyle};
`;

export const Label = styled.label`
  ${LabelStyle};
`;

export const InputColor = css`
  opacity: 1;
  color: ${({ theme }) => theme.colors.black};
`;

export const FocusedInputStyle = css`
  box-shadow: 0px 3px 6px #00000026;
`;

export const ErrorInputStyle = css`
  ${ErrorTextStyle};
`;

export const InputStyle = css`
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px 15px;
  width: 100%;
  outline: none;
  ${InputColor};
  ${({ error }) => error && ErrorInputStyle};
  ${({ focused }) => focused && FocusedInputStyle};
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

export const InputContainer = styled.div`
  width: 100%;
  background: ${({ theme: { colors } }) => colors.white};
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  padding: ${({ large }) => (large ? "10px" : 0)};
  margin: ${({ large }) => (large ? "20px 0" : 0)};
`;

export const ErrorMessageBox = styled.div`
  background: ${({ theme }) => theme.colors.error} !important;
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  overflow: auto;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 10px;
  max-width: 300px;
  font-size: 16px;
`;
