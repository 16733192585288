import React, { useState } from "react";
import Section from "../../components/Section";
import styled from "styled-components";
import { Container } from "../../components/Container";
import FeatureBox from "../../components/FeatureBox/FeatureBox";
import { up } from "styled-breakpoints";
import theme from "../../../theme";
import { Slide } from "react-reveal";

import Modal from 'react-bootstrap/Modal';

const FeaturesList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;

  ${up("lg")} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ModalTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray};
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleItemModal = styled.div`
  color: ${({ theme }) => theme.colors.lightText};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  margin-top: ${({ noMargin }) => noMargin ? 0 : '20px'};
  font-weight: 600;
`;

const ContentItemModal = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: #2d354b;
  font-size: 16px;
  font-weight: 600;
`;

const ButtonHeader = styled.button`
  background-color: #19CEA6;
  border-radius: 3px;
  height: 31px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  box-shadow: 0px 2px 5px #00000029;
  font-family: ${({ theme }) => theme.fonts.primary};
  padding: 0 15px;
  width: 100%;
`;

const ProjectsSection = () => {
  const [showModal, setShowModal] = useState(null);

  const handleClose = () => setShowModal(null);

  const features = [
    {
      title: "360 Smart trade",
      paragrafy: 'Conheça o projeto',
      text: `Sistema web para gestão de vendas, controle financeiro, comissão estoque. \nCadastro e controle de vendedores, comissão por produto, por pedido, relatório gerenciais, entrada e saída de estoque, CRM de clientes, registro de cobranças e contatos.
      `,
      imagePath: 'projects/img01.png'
    },
    {
      title: "Scaratti 4Web",
      paragrafy: 'Conheça o projeto',
      text: `Produto da SCARATTI Soluções em TI focado em vendas pronta entrega com emissão de boletos e NF-e. Permite realizar vendas em qual local mesmo sem internet, pois possui tecnologia para emissão de pedidos, boletos e NF-e offline. \nPossui módulo de impressão via bluetooth para imprimir tudo na hora na venda! \nIntegração com cartão de créditos e rede bancária.`,
      imagePath: 'projects/img03.png'
    },
    {
      title: "Colaborador Online",
      paragrafy: 'Conheça o projeto',
      text: `Portal de adesão para ação coletiva através de sindicatos parceiros com objetivo reaver descontos indevidos na folha. Portal gerencia adesão, sindicatos, associados e conta com integração com Santander e Pagar.ME como meios de pagamentos das adesões.`,
      imagePath: 'projects/img02.png'
    },
    {
      title: "Granero Transportes Ltda",
      paragrafy: 'Conheça o projeto',
      text: `Parceiro de anos da líder no segmento de mudanças de alto padrão, a SCARATTI desenvolveu projetos de vistorias, dashboard, controle de gestão de notas de débitos, portal institucional além de outros sistemas internos que auxiliam a gestão da Franquias. \nProjetos com integrações com meios de pagamentos, ERPs, rastreadores além da gestão e manutenção dos sistemas desenvolvidos.`,
      imagePath: 'projects/img04.png'
    },
  ];

  return (
    <>
      <Section.Container id="Projetos">
        <Container>
          <Section.Header name="Projetos"></Section.Header>
          <Slide bottom cascade>
            <FeaturesList>
              {features.map((feature, index) => (
                <FeatureBox
                  key={index}
                  title={`${feature.title}`}
                  description={feature.description}
                  href={feature.href ? feature.href : null}
                  pushImageToBottom={feature.pushImageToBottom}
                  backgroundColor={theme.colors.lightGray}
                  fullWidth={true}
                  badgeNumber={index + 1}
                  paragrafy={`${feature.paragrafy}`}
                  SVGImage={() => { return feature.svgImage }}
                  imagePath={feature.imagePath}
                  largeImage={true}
                  onLinkClick={() => {
                    setShowModal(feature);
                  }}
                />
              ))}
            </FeaturesList>
          </Slide>
        </Container>
      </Section.Container>

      <Modal show={(showModal !== null)} onHide={handleClose} centered>
        <Modal.Header style={{ borderBottom: 0 }} closeButton>
          <Modal.Title>
            <ModalTitle>
              Detalhe do projeto
            </ModalTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ whiteSpace: 'pre-wrap' }}>
          <ModalBody>
            <>
              <TitleItemModal noMargin>
                Nome
              </TitleItemModal>
              <ContentItemModal>
                {showModal?.title}
              </ContentItemModal>
            </>

            <>
              <TitleItemModal>
                Descrição
              </TitleItemModal>
              <ContentItemModal>
                {showModal?.text}
              </ContentItemModal>
            </>
          </ModalBody>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 0 }}>
          <ButtonHeader onClick={handleClose}>Fechar</ButtonHeader>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectsSection;
