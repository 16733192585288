const generateBaseStyles = (colors) => {
  return {
    position: "bottom-right",
    closeStyle: {
      color: colors.white,
      fontSize: "16px",
    },
  };
};

export const errorSnackbar = (colors) => {
  return {
    ...generateBaseStyles(colors),
    style: {
      backgroundColor: colors.error,
      color: colors.white,
    },
  };
};

export const successSnackbar = (colors) => {
  return {
    ...generateBaseStyles(colors),
    style: {
      backgroundColor: colors.lightGreen,
      color: colors.white,
    },
  };
};
