import React from "react";
import { Fade } from "react-reveal";
import { down } from "styled-breakpoints";

import styled from "styled-components";

const FooterSectionContainer = styled.div`
  width: 100%;
  height:100%;
  background-color: ${({ theme }) => theme.colors.Green};
`;

const PFooter = styled.p`
font-size: 15px;
font-weight: 500;
color:  ${({ theme }) => theme.colors.white};
margin-left: 80px;
padding: 20px;
margin-bottom: 0;

${down("md")} {
    margin:0;
}
`;

const FooterSection = () => {
  return (
    <>
      <FooterSectionContainer id="">
        <PFooter>@{new Date().getFullYear()} Scaratti Soluções em TI - CNPJ 10.836.207/0001-54 | All right reserved.</PFooter>
      </FooterSectionContainer>
    </>
  );
};

export default FooterSection;
