import React from "react";
import Headroom from "react-headroom";
import styled, { css } from "styled-components";
import { SectionLinks } from "react-scroll-section";
import { up } from "styled-breakpoints";
import { navigate } from "gatsby";
import { globalHistory } from "@reach/router";

import RouteLink from "../RouteLink";
import LogoImageSVG from "../../assets/images/logo/logo-image.inline.svg";
import { capitalizeString, arrayContainsString } from "../../utils";
import { isFilled } from "../../validators";
import { Media } from "../Media";
import { Button } from "../Button";
import { Container } from "../Container";

const LogoImage = styled(LogoImageSVG)`
width: 100%;
height: 100%;
`;

const ButtonHeader = styled.button`
  margin-left: 30px;
  background-color: #19CEA6;
  border-radius: 3px;
  width: 99px;
  height: 31px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  margin-top: 7px;
  box-shadow: 0px 2px 5px #00000029;
  font-family: ${({ theme }) => theme.fonts.primary};
  padding: 0 15px;
`;

const BaseLinksStyle = css`
  li:first-of-type {
    margin-left: 0 !important;
  }
  li:last-of-type {
    margin-right: 0 !important;
  }
`;

const DesktopLinksContainer = styled.ul`
  flex-direction: row;
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: 85px;
  ${BaseLinksStyle}; 
`;

const MobileNavLinksContainer = styled.ul`
  flex-direction: row;
  display: flex;
  margin: 0;
  white-space: nowrap;
  overflow-x: scroll;
  max-width: calc(100vw - ${({ theme }) => theme.grid.horizontalPadding} * 2);
  ${BaseLinksStyle};
`;

const LogoContainer = styled.div`
  padding: 10px 5px;
  width: 150px;
  cursor: pointer;

  ${up("lg")} {
    padding: 5px;
  }
`;

const BaseNavLinksContainer = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  background: white;
`;

const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  /**
    * Justify the button on the right side
    */
  ${Button} {
    margin-left: auto;
  }
`;

const HeaderStyles = css`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 1px 21px #0000000d;
  min-height: 80px;
  overflow: auto;
  padding: 0;
`;

const HeadroomHeader = styled(Headroom)`
  width: 100%;
  opacity: 1;

  .headroom--pinned {
    transform: translateY(0%);
    z-index: 100 !important;
    display: flex;
    align-items: center;
    ${HeaderStyles};
  }

  .headroom--unfixed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${HeaderStyles};
  }
`;

const formatLinks = (allLinks) => {
  console.log(allLinks);
  const filledLinks = Object.entries(allLinks).filter(([linkName]) =>
    isFilled(linkName)
  );

  return filledLinks.reduce(
    (acc, [linkID, linkValue]) => {
      const isHome = linkID === "home";

      return isHome
        ? {
            ...acc,
            home: linkValue,
          }
        : {
            ...acc,
            links: [
              ...acc.links,
              { name: capitalizeString(linkID), value: linkValue },
            ],
          };
    },
    { links: [], home: null }
  );
};

export const Header = () => {
  const redirectToHomePage = () => {
    navigate("/");
  };

  return (
    <>
      <HeadroomHeader>
        <HeaderContainer>
          <SectionLinks>
            {({ allLinks }) => {
              const { links } = formatLinks(allLinks);

              const path = globalHistory.location.pathname;

              const HomeLogoLink = (
                <a onClick={() => redirectToHomePage()} aria-label="Home link">
                   <LogoContainer>
                  <LogoImage />
                  </LogoContainer>
                </a>
              );

              const HomeLink = (
                <RouteLink
                  key="home"
                  name="Início"
                  onClick={() => redirectToHomePage()}
                  selected={path === "/"}
                />
              );

              const omittedLinksFromMenu = [];

              const navLinks = links
                .filter(
                  (link) =>
                    !arrayContainsString(omittedLinksFromMenu, link.name)
                )
                .map(({ name, value: link }) => {
                  return (
                    <RouteLink key={name} name={name} onClick={link.onClick} />
                  );
                });

              const buttonContact = (
                <ButtonHeader onClick={() => {
                  document.getElementById('Contato').scrollIntoView({ 
                    behavior: 'smooth' 
                  });
                }}>Contato</ButtonHeader>
              )

              navLinks.unshift(HomeLink);
              navLinks.push(buttonContact);

              return (
                <>
                  <BaseNavLinksContainer>
                    {HomeLogoLink}
                    <Media greaterThanOrEqual="lg">
                      <DesktopLinksContainer>{navLinks}</DesktopLinksContainer>
                    </Media>
                  </BaseNavLinksContainer>
                  <Media between={["sm", "lg"]}>
                    <MobileNavLinksContainer>
                      {navLinks}
                    </MobileNavLinksContainer>
                  </Media>
                </>
              );
            }}
          </SectionLinks>
        </HeaderContainer>
      </HeadroomHeader>
    </>
  );
};
